<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    id="bubbles"
    class="relative"
    :style="{ 'padding-top': startHeight + 'px' }"
    @mouseover="
      setCursor(null, scrollTo, {
        'font-size': '14px',
        cursor: 'unset',
        'z-index': '-1',
      })
    "
    @mouseout="onMouseOut"
  >
    <canvas :ref="refProp" :style="{ height: height + 'px' }" height="height" />
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      required: true,
    },
    startWidth: {
      type: Number,
      required: true,
    },
    startHeight: {
      type: Number,
      default: 0,
      // required: true,
    },
    setCursor: {
      required: true,
      type: Function,
    },
    refProp: {
      type: String,
      default: "canvas",
    },
  },
  data() {
    return {
      project: null,
      ballPositions: [
        // [255, 129],
        // [610, 73],
        // [486, 363],
        // [117, 459],
        // [484, 726],
        // [843, 306],
        // [789, 615],
        // [1049, 82],
        // [1292, 428],
        // [1117, 733],
        // [1352, 86],
        // [92, 798],
      ],
      handle_len_rate: 2.4,
      circlePaths: [],
      largeCircle: null,
      connections: null,
      largeCirclePosition: null,
    };
  },
  mounted() {
    // paper.install(window);
    paper.setup(this.$refs[this.refProp]);

    const GRID_GAP = 130;
    const rows = Math.ceil(this.height / GRID_GAP);
    for (let i = 0; i < rows; i++) {
      let x = this.startWidth;
      while (x < this.$refs[this.refProp].clientWidth) {
        this.ballPositions.push([x, i + i * GRID_GAP + 30]);
        x += GRID_GAP;
      }
    }

    this.initMetaball();
    window.addEventListener("resize", this.resizeHandler);
  },
  unmounted() {
    if (this.project) {
      this.project.clear();
    }
    this.circlePaths = [];
    this.ballPositions = [];
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    initMetaball() {
      this.project = paper.project;

      this.project.view.onMouseMove = this.onMouseMove;
      this.project.currentStyle = {
        // fillColor: '#FFC800',
      };

      for (let i = 0, l = this.ballPositions.length; i < l; i++) {
        const circlePath = new paper.Path.Circle({
          center: this.ballPositions[i],
          radius: 20,
        });
        circlePath.strokeColor = "#FFC800";
        circlePath.fillColor = null;
        this.circlePaths.push(circlePath);
      }

      this.largeCircle = new paper.Path.Circle({
        center: [-100, -100],
        radius: 40,
      });
      this.largeCirclePosition = this.largeCircle.position;
      this.largeCircle.fillColor = "#FFC800";
      this.circlePaths.push(this.largeCircle);

      this.connections = new paper.Group();
      this.generateConnections(this.circlePaths);
    },
    onMouseMove(event) {
      // console.log('mouseMe');
      if (!this.$refs[this.refProp]) return;

      const bounds = {
        minX: 40,
        minY: 40,
        maxX: this.$refs[this.refProp].clientWidth - 40,
        maxY: this.height - 40,
      };

      // Check if the event point is within the bounds
      if (event.point.x < bounds.minX) event.point.x = bounds.minX;
      if (event.point.y < bounds.minY) event.point.y = bounds.minY;
      if (event.point.x > bounds.maxX) event.point.x = bounds.maxX;
      if (event.point.y > bounds.maxY) event.point.y = bounds.maxY;
      this.largeCircle.position = event.point;
      this.largeCirclePosition = event.point;

      this.generateConnections(this.circlePaths);
      // this.project.view.update();
    },
    generateConnections(paths) {
      this.connections.removeChildren();
      for (let i = 0, l = paths.length - 1; i < l; i++) {
        // console.log('generate 1', i)
        // const path = this.metaball(paths[i], this.largeCircle, 0.5, this.handle_len_rate, 120);
        // if (path) {
        //     this.connections.appendTop(path);
        //     path.removeOnMove();
        // }
        const path2 = this.metaball(
          this.largeCircle,
          paths[i],
          0.5,
          this.handle_len_rate,
          120,
        );
        if (path2) {
          this.connections.appendTop(path2);
          // path2.removeOnMove();
        }
        // for (let j = i - 1; j >= 0; j--) {
        //     // console.log('generate 2', j)
        //
        // }
      }
    },
    metaball(ball1, ball2, v, handle_len_rate, maxDistance) {
      const center1 = this.largeCirclePosition;
      const center2 = ball2.position;
      let radius1 = ball1.bounds.width * 0.5;
      let radius2 = ball2.bounds.width * 0.5;
      const pi2 = Math.PI * 0.5;
      const d = center1.getDistance(center2);
      let u1, u2;

      if (radius1 === 0 || radius2 === 0) return;

      if (d > maxDistance || d <= Math.abs(radius1 - radius2)) {
        ball2.fillColor = null;
        return;
      } else if (d < radius1 + radius2) {
        u1 = Math.acos(
          (radius1 * radius1 + d * d - radius2 * radius2) / (2 * radius1 * d),
        );
        u2 = Math.acos(
          (radius2 * radius2 + d * d - radius1 * radius1) / (2 * radius2 * d),
        );
      } else {
        u1 = 0;
        u2 = 0;
      }

      ball2.fillColor = "#FFC800";
      const angle1 = center2.subtract(center1).getAngleInRadians();
      const angle2 = Math.acos((radius1 - radius2) / d);
      const angle1a = angle1 + u1 + (angle2 - u1) * v;
      const angle1b = angle1 - u1 - (angle2 - u1) * v;
      const angle2a = angle1 + Math.PI - u2 - (Math.PI - u2 - angle2) * v;
      const angle2b = angle1 - Math.PI + u2 + (Math.PI - u2 - angle2) * v;
      const p1a = center1.add(this.getVector(angle1a, radius1));
      const p1b = center1.add(this.getVector(angle1b, radius1));
      const p2a = center2.add(this.getVector(angle2a, radius2));
      const p2b = center2.add(this.getVector(angle2b, radius2));

      const totalRadius = radius1 + radius2;
      let d2 = Math.min(
        v * handle_len_rate,
        p1a.subtract(p2a).length / totalRadius,
      );

      d2 *= Math.min(1, (d * 2) / (radius1 + radius2));

      radius1 *= d2;
      radius2 *= d2;

      const path = new paper.Path({
        segments: [p1a, p2a, p2b, p1b],
        style: ball1.style,
        closed: true,
      });
      const segments = path.segments;

      segments[0].handleOut = this.getVector(angle1a - pi2, radius1);
      segments[1].handleIn = this.getVector(angle2a + pi2, radius2);
      segments[2].handleOut = this.getVector(angle2b - pi2, radius2);
      segments[3].handleIn = this.getVector(angle1b + pi2, radius1);
      return path;
    },
    getVector(radians, length) {
      return new paper.Point({
        angle: (radians * 180) / Math.PI,
        length,
      });
    },
    scrollTo() {},
    resizeHandler() {
      if (this.project) {
        this.project.clear();
      }
      this.circlePaths = [];
      this.ballPositions = [];

      const GRID_GAP = 130;
      const rows = Math.ceil(this.height / GRID_GAP);

      for (let i = 0; i < rows; i++) {
        let x = this.startWidth;
        if (!this.$refs[this.refProp]) return;
        while (x < this.$refs[this.refProp].clientWidth) {
          this.ballPositions.push([x, i + i * GRID_GAP + 30]);
          x += GRID_GAP;
        }
      }

      this.project.activate();
      this.initMetaball();
    },
    onMouseOut() {
      this.largeCircle.position = new paper.Point(-120, 0);
      this.largeCirclePosition = new paper.Point(-120, 0);
      this.generateConnections(this.circlePaths);
    },
    onMouseEnter() {
      this.project.activate();
    },
  },
};
</script>

<style scoped>
canvas {
  width: 100% !important;
  /*height: 700px;*/
  display: block;
  background-color: #fff;
}
</style>
